import axios from 'axios';

export default ({
  state: {
    meditationsList: [
      {
        groupName: 'ya-skoro-budu-mamoy',
        saleForAll: 0,
        title: 'Я скоро буду мамой',
        singleTitle: 'Медитации для планирования беременности и благополучных родов',
        mainColor: '#FD84AB',
        isPayed: false,
        mainSliderImage: 'images/groups-bg/group-1.jpg',
        meditationTheme: 'light',
        mainSliderDescr: 'Третий модуль подготовит вас к беременности и родам. Но сначала пройдите предыдущие модули.',
        reviews: [
          'https://elena.shop/public/audio_reviews/__1.jpg',
          'https://elena.shop/public/audio_reviews/__2.jpg',
          'https://elena.shop/public/audio_reviews/__3.jpg',
          'https://elena.shop/public/audio_reviews/__4.jpg',
          'https://elena.shop/public/audio_reviews/__5.jpg',
          'https://elena.shop/public/audio_reviews/__6.jpg',
          'https://elena.shop/public/audio_reviews/__7.jpg',
          'https://elena.shop/public/audio_reviews/__8.jpg',
          'https://elena.shop/public/audio_reviews/__9.jpg',
          'https://elena.shop/public/audio_reviews/__10.jpg',
          'https://elena.shop/public/audio_reviews/__11.jpg',
        ],
        mainHeaderBg: '/images/groups-bg/inner-group-1.png',
        playerImage: '/images/groups-bg/player-2.jpg',
        list: [
          {
            url: 'nastroy-na-materinstvo',
            id: 1,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/1.png',
            groupId: 'ya-skoro-budu-mamoy',
            isActive: true,
            type: 'mediatation',
            name: 'Настрой на материнство',
            nameHTML: 'Шаг 8',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Это одна из наиболее востребованных моих медитаций, она действительно творит чудеса. Более 1000 женщин с различными врачебными диагнозами смогли забеременеть благодаря этой медитации. Главная задача этой медитации - достучаться и поговорить со своим женским центром - маткой. Те, у кого это получится, почувствуют невероятное расслабление внутри и ощутят огромную женскую силу.</p>',
            discrTime: '23 мин',
            discrPrice: 25,
            audio: 'https://elena.shop/public/audio/nastroy_na_materinstvo_2.mp3',
            audioPreview: 'https://elena.shop/public/audio/nastroy_na_materinstvo-preview_2.mp3',
            reviews: [
              '/images/review/1-3-1.jpg',
              '/images/review/1-3-2.jpg',
              '/images/review/1-3-3.jpg',
              '/images/review/1-3-4.jpg',
              '/images/review/1-3-5.jpg',
              '/images/review/1-3-6.jpg',
              '/images/review/1-3-7.jpg',
              '/images/review/1-3-8.jpg',
              '/images/review/1-3-9.jpg',
              '/images/review/1-3-10.jpg',
              // '/images/review/1-3-11.jpg',
              // '/images/review/1-3-12.jpg',
              // '/images/review/1-3-13.jpg',
              // '/images/review/1-3-14.jpg',
              // '/images/review/1-3-15.jpg',
              // '/images/review/1-3-16.jpg',
              // '/images/review/1-3-17.jpg',
              // '/images/review/1-3-18.jpg',
              // '/images/review/1-3-19.jpg',
              // '/images/review/1-3-20.jpg',
              // '/images/review/1-3-21.jpg',
              // '/images/review/1-3-22.jpg',
              // '/images/review/1-3-23.jpg',
              // '/images/review/1-3-24.jpg',
              // '/images/review/1-3-25.jpg',
              // '/images/review/1-3-26.jpg',
            ],
            getCourseIframeID: 1043255,
            getCoursePrice: 1000,
          },
          {
            url: 'pereprojivaniye-i-isceleniye-rojdeniya',
            id: 15,
            isNew: true,
            promoImage: '/images/meditations-bg-sq/15.png',
            groupId: 'new-section',
            isActive: true,
            type: 'mediatation',
            name: 'Перепроживание и исцеление процесса собственного рождения',
            nameHTML: 'Шаг 3',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Каждой женщине, идущей к себе истинной, к свободе, счастью и реализации во всех сферах, необходимо обратить своё внимание на то, как проходил процесс ее собственного рождения. </p>'
            + '<p>Не каждая Мама того времени может похвастаться благостными спокойными родами, где нет места страху, больничным ярким лампам и грубости медперсонала.</p>'
            + '<p>Довольно часто наше Рождение сопровождалось страхом нашей мамы, беспомощностью, и недоверием себе. В этом процессе не было внутренней силы, ощущения безопасности и радости от рождения нового человека.</p>'
            + '<p>Все те ощущения, эмоции которые испытывает мама, закладываются в наше бессознательное, формируются базовые стратегии и глубинные установки. Они во многом влияют на то, как складывается наша жизнь, как мы умеем или не умеем справляться с задачами, чувствуем ли мы безопасность этого мира, доверяем ли мы людям и многое другое.</p>'
            + '<p>В этой целительной терапевтической медитации вы сможете исцелить и перепрожить важный момент собственного рождения в любви и радости, вернете свой ресурс, который принадлежит вам по праву, во благо вам и ваших будущих детей.</p>',
            discrTime: '27 мин',
            discrReviews: [],
            discrPrice: 25,
            audio: 'https://elena.shop/public/audio/pereprojivaniye-i-isceleniye-rojdeniya.mp3',
            audioPreview: 'https://elena.shop/public/audio/pereprojivaniye-i-isceleniye-rojdeniya_preview.mp3',
            playerImage: '/images/groups-bg/player-2.jpg',
            mainColor: '#FD84AB',
            getCourseIframeID: 1043264,
            getCoursePrice: 1000,
            reviews: [
            ],
          },
          {
            url: 'blagopoluchnaya-i-zdorovaya-beremennost',
            id: 7,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/7.png',
            groupId: 'ya-skoro-budu-mamoy',
            isActive: false,
            type: 'mediatation',
            name: 'Благополучная и здоровая беременность',
            nameHTML: 'Шаг 10',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Эту медитацию я создала как для тех, кто уже увидел свои заветные две полоски, так и для тех, кто только планирует беременность. Главная задача этой медитации - настроиться на гармоничную и здоровую беременность, наладить связь со своим малышом, дать ему и себе поддержку, чувство безопасности, любви и принятия. Тем, кто только планирует беременность медитация поможет погрузиться в это волшебное состояние материнства, прожить его в своём теле. Доказано, что мозг не видит никакой разницы между нашим воображением и реальностью. Ваше тело будет чувствовать всё то, что вы “нарисуете” в своем воображении.</p>',
            discrTime: '22 мин',
            discrReviews: [],
            discrPrice: 20,
            audio: 'https://elena.shop/public/audio/blagopoluchnaya_i_zdorovaya_beremennost.mp3',
            audioPreview: 'https://elena.shop/public/audio/blagopoluchnaya_i_zdorovaya_beremennost-preview.mp3',
            getCourseIframeID: 1047801,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/1-1-1.jpg',
              '/images/review/1-1-2.jpg',
              '/images/review/1-1-3.jpg',
              '/images/review/1-1-4.jpg',
              '/images/review/1-1-5.jpg',
              '/images/review/1-1-6.jpg',
              '/images/review/1-1-7.jpg',
              '/images/review/1-1-8.jpg',
              '/images/review/1-1-9.jpg',
              '/images/review/1-1-10.jpg',
              '/images/review/1-1-11.jpg',
              '/images/review/1-1-12.jpg',
              '/images/review/1-1-13.jpg',
              '/images/review/1-1-14.jpg',
            ],
          },
          {
            url: 'otpuskanie-dushi-nerozhdennogo-rebenka',
            id: 4,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/4.png',
            groupId: 'ya-skoro-budu-mamoy',
            isActive: false,
            type: 'mediatation',
            name: 'Отпускание души нерожденного ребенка',
            nameHTML: 'Бонус',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Медитация поможет "навести порядок" в вашей родовой системе. Если у ваших мам или бабушек были аборты, замершие беременности, внематочные беременности, при помощи медитации вы дадите место этим деткам в вашем роду.</p> <p>Если в вашей жизни были такие случаи, вы обязательно должны пройти медитацию. Главная задача этой медитации - отпустить нерожденного малыша, дать ему место в вашей родовой системе, принять его выбор и получить прощение. Во время медитации дайте свободу своим эмоциям, если захотите плакать - не сдерживайте себя. Вы отпустите всё напряжение в теле, почувствуете невероятное облегчение внутри, тело расслабиться и будет готово к новой беременности.</p>',
            discrTime: '14 мин',
            discrReviews: [],
            discrPrice: 20,
            audio: 'https://elena.shop/public/audio/otpuskanie_nerozdennogo_malisha.mp3',
            audioPreview: 'https://elena.shop/public/audio/otpuskanie_nerozdennogo_malisha-preview.mp3',
            getCourseIframeID: 1047805,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/1-4-1.jpg',
              '/images/review/1-4-2.jpg',
              '/images/review/1-4-3.jpg',
              '/images/review/1-4-4.jpg',
              '/images/review/1-4-5.jpg',
              '/images/review/1-4-6.jpg',
              '/images/review/1-4-7.jpg',
              '/images/review/1-4-8.jpg',
              '/images/review/1-4-9.jpg',
              '/images/review/1-4-10.jpg',
              // '/images/review/1-4-11.jpg',
              // '/images/review/1-4-12.jpg',
              // '/images/review/1-4-13.jpg',
              // '/images/review/1-4-14.jpg',
              // '/images/review/1-4-15.jpg',
              // '/images/review/1-4-16.jpg',
              // '/images/review/1-4-17.jpg',
              // '/images/review/1-4-18.jpg',
            ],
          },
        ],
      },
      {
        groupName: 'ya-prinimayu-sebya',
        saleForAll: 0,
        title: 'Я принимаю себя',
        singleTitle: 'Медитации на принятие себя, для раскрытия женственности и сексуальности',
        mainColor: '#39BDEB',
        mainSliderImage: 'images/groups-bg/group-2.jpg',
        meditationTheme: 'light',
        mainSliderDescr: 'Второй модуль самый нежный и женственный. Вы научитесь принимать себя, жить в гармонии и любви.',
        isPayed: false,
        mainHeaderBg: '/images/groups-bg/inner-group-2.png',
        playerImage: '/images/groups-bg/player-3.jpg',
        list: [
          {
            url: 'energeticheskaya-meditaciya-na-samoiscelenie-lyubogo-simptoma',
            id: 17,
            isNew: true,
            promoImage: '/images/meditations-bg-sq/17.png',
            groupId: 'new-section',
            isActive: true,
            type: 'mediatation',
            name: 'Энергетическая медитация на самоисцеление любого симптома',
            nameHTML: 'Шаг 5',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Эта медитация поможет вам принять ваш телесный симптом, увидеть и осознать его причину, поможет растворить весь дискомфорт, который ощущает ваше тело. Эта практика подходит для работы с любым телесным симптомом или диагнозом.</p><p>Идите в эту практику легко и без избыточных ожиданий. Ваше тело очень ждет вашего внимания и бережного участия.</p>',
            discrTime: '16 мин',
            discrReviews: [],
            discrPrice: 25,
            audio: 'https://elena.shop/public/audio/energeticheskaya-meditaciya-na-samoiscelenie-lyubogo-simptoma.mp3',
            audioPreview: 'https://elena.shop/public/audio/energeticheskaya-meditaciya-na-samoiscelenie-lyubogo-simptoma_preview.mp3',
            playerImage: '/images/groups-bg/player-3.jpg',
            mainColor: '#39BDEB',
            getCourseIframeID: 1047809,
            getCoursePrice: 1000,
            reviews: [
            ],
          },
          {
            url: 'energeticheskaya-praktika-na-privlechenie-sobytij',
            id: 16,
            isNew: true,
            promoImage: '/images/meditations-bg-sq/16.png',
            groupId: 'new-section',
            isActive: true,
            type: 'mediatation',
            name: 'Энергетическая практика на привлечение желаемых событий',
            nameHTML: 'Шаг 4',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Эта практика поможет воплотить ваши истинные желания в жизнь, с её помощью вы станете Магом своей реальности. Я создала для вас эту практику, потому что сама часто делаю её и меняю свою жизнь к лучшему. Пользуйтесь на благо!</p>',
            discrTime: '12 мин',
            discrReviews: [],
            discrPrice: 25,
            audio: 'https://elena.shop/public/audio/energeticheskaya-praktika-na-privlechenie-sobytij.mp3',
            audioPreview: 'https://elena.shop/public/audio/energeticheskaya-praktika-na-privlechenie-sobytij_preview.mp3',
            playerImage: '/images/groups-bg/player-3.jpg',
            mainColor: '#39BDEB',
            getCourseIframeID: 1047810,
            getCoursePrice: 1000,
            reviews: [
            ],
          },
          {
            url: 'angelskij-potok-isceleniya',
            id: 14,
            isNew: true,
            promoImage: '/images/meditations-bg-sq/14.png',
            groupId: 'new-section',
            isActive: true,
            type: 'mediatation',
            name: 'Ангельский поток исцеления',
            nameHTML: 'Шаг 2',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Эта медитативная энергетическая практика поможет осознать и отпустить препятствия, которые лежат на пути к вашей мечте, почувствовать незримое присутствие вашей духовной поддержки.</p>'
                   + '<p>В этой практике вы соединитесь с Высшими силами света, чтобы отпустить то, что вам мешает сейчас реализовать свою мечту.</p>'
                   + '<p>Подготовьте лист формата А4 и ручку, устройтесь удобно, попросите ваших любимых не беспокоить вас в течение 40 минут. Будьте готовы легко и без избыточных ожиданий погрузиться в чудесный мир вашей души.</p>',
            discrTime: '40 мин',
            discrReviews: [],
            discrPrice: 25,
            mainColor: '#39BDEB',
            audio: 'https://elena.shop/public/audio/angelskij_potok_isceleniya.mp3',
            audioPreview: 'https://elena.shop/public/audio/angelskij_potok_isceleniya_preview.mp3',
            getCourseIframeID: 1047814,
            getCoursePrice: 1000,
            reviews: [
            ],
          },
          {
            url: 'na-raskrytie-zhenskoj-seksualnosti',
            id: 13,
            isNew: true,
            promoImage: '/images/meditations-bg-sq/13.png',
            groupId: 'new-section',
            isActive: true,
            type: 'mediatation',
            name: 'На раскрытие женственности и сексуальности',
            nameHTML: 'Шаг 1',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Проявление своей сексуальности у многих женщин, к сожалению, связано с огромным количеством навязанных стереотипов. Стыд, страх, самообесценивание себя как женщины стали постоянными спутниками, которые держат в напряжении тело, рушат отношения с партнёром, жизнь становится не такой яркой, какой может быть.<br />Настало время меняться, развивать в себе свой природный потенциал, свою женскую силу. Ведь все начинается с нас. Именно мы задаём тон взаимоотношениям, создаём гармоничную атмосферу в семье, рожаем детей.</p>\n'
                   + '<p>Я создала для вас эту медитацию для того, чтобы вы могли соединиться со своей женской силой и сексуальностью, прочувствовать свою истинную женскую энергию, разблокировать телесные зажимы, выпустить наружу то, что вы так долго прятали от себя.</p>\n'
                   + '<p>Эта динамическая медитация поможет вашему телу расслабиться и прочувствовать новые грани себя через танец. Танец, который раскроет в вас истинную женщину, вашу уникальную сексуальность.</p>\n'
                   + '<p>Медитация длится 30 минут. Уделите себе это время, позаботьтесь о том, чтобы вас никто не беспокоил, и вы могли побыть самой собой без любых суждений и ограничений.</p>',
            discrTime: '32 мин',
            discrReviews: [],
            discrPrice: 25,
            mainColor: '#39BDEB',
            audio: 'https://elena.shop/public/audio/na-raskrytie-zhenskoj-seksualnosti.mp3',
            audioPreview: 'https://elena.shop/public/audio/na-raskrytie-zhenskoj-seksualnosti-preview.mp3',
            getCourseIframeID: 1047819,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/1-5-1.jpg',
              '/images/review/1-5-2.jpg',
              '/images/review/1-5-3.jpg',
            ],
          },
          {
            url: 'na-blagodarnost-i-prinyatie-sebyas',
            id: 10,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/10.png',
            groupId: 'ya-prinimayu-sebya',
            isActive: true,
            type: 'mediatation',
            name: 'На благодарность и принятие себя',
            nameHTML: 'Шаг 5',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Это одна из самых нежных и глубоких медитаций. Она поможет вам максимально расслабиться и проникнуться любовью к своему телу. Главная задача медитации - достучаться до вас настоящей, научиться любить себя такой, какая вы есть. После медитации вы ощутите невероятную благодарность своему телу, наполнитесь любовью и светом.</p>',
            discrTime: '25 мин',
            discrReviews: [],
            discrPrice: 25,
            audio: 'https://elena.shop/public/audio/na_blagodarnost.mp3',
            audioPreview: 'https://elena.shop/public/audio/na_blagodarnost-preview.mp3',
            getCourseIframeID: 1047824,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/3-4-1.jpg',
              '/images/review/3-4-2.jpg',
              '/images/review/3-4-3.jpg',
              '/images/review/3-4-4.jpg',
              '/images/review/3-4-5.jpg',
              '/images/review/3-4-6.jpg',
              '/images/review/3-4-7.jpg',
              '/images/review/3-4-8.jpg',
              '/images/review/3-4-9.jpg',
              '/images/review/3-4-10.jpg',
              // '/images/review/3-4-11.jpg',
              // '/images/review/3-4-12.jpg',
              // '/images/review/3-4-13.jpg',
              // '/images/review/3-4-14.jpg',
            ],
          },
          {
            url: 'vstrecha-so-svoim-duhovnym-nastavnikom',
            id: 11,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/11.png',
            groupId: 'ya-prinimayu-sebya',
            isActive: false,
            type: 'mediatation',
            name: 'Встреча со своим Духовным Наставником',
            nameHTML: 'Шаг 6',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Эта волшебная медитация для работы с подсознанием. Её нужно практиковать, когда вы находитесь в поиске важных ответов или в процессе принятия сложного для себя решения. Главная задача медитации - развить навык общения со своим Высшим Я, прокачать интуицию. После медитации вы начнете слушать и слышать себя, принимать правильные решения, жить своей жизнью.</p>',
            discrTime: '30 мин',
            discrReviews: [],
            discrPrice: 20,
            audio: 'https://elena.shop/public/audio/duhovniy-nastavnik.mp3',
            audioPreview: 'https://elena.shop/public/audio/duhovniy-nastavnik_preview.mp3',
            getCourseIframeID: 1047828,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/3-1-1.jpg',
              '/images/review/3-1-2.jpg',
              '/images/review/3-1-3.jpg',
              '/images/review/3-1-4.jpg',
              '/images/review/3-1-5.jpg',
              '/images/review/3-1-6.jpg',
              '/images/review/3-1-7.jpg',
            ],
          },
          {
            url: 'ispolnenie-sokrovennogo-zhelaniya',
            id: 5,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/5.png',
            groupId: 'ya-prinimayu-sebya',
            isActive: false,
            type: 'mediatation',
            name: 'Исполнение сокровенного желания',
            nameHTML: 'Шаг 7',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Это волшебная медитация творит чудеса. Вы сделаете мощную технику по загадыванию желания и сможете повторять её много раз. Главная задача этой медитации - научиться доверять миру и принимать подарки судьбы. После медитации, вы станете более спокойной и лёгкой, поверите в чудо. Перед тем, как вы начнете слушать медитацию, определитесь со своим желанием.</p>',
            discrTime: '15 мин',
            discrReviews: [],
            discrPrice: 20,
            audio: 'https://elena.shop/public/audio/ispolnenie_zelaniy.mp3',
            audioPreview: 'https://elena.shop/public/audio/ispolnenie_zelaniy-preview.mp3',
            getCourseIframeID: 1047832,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/3-2-1.jpg',
              '/images/review/3-2-2.jpg',
              '/images/review/3-2-3.jpg',
              '/images/review/3-2-4.jpg',
              '/images/review/3-2-5.jpg',
            ],
          },
        ],
      },
      {
        groupName: 'ya-nichego-ne-boyus',
        saleForAll: 0,
        title: 'Я ничего не боюсь',
        singleTitle: 'Медитации для освобождения от страхов, обид, тревог и восстановления сил',
        mainSliderImage: 'images/groups-bg/group-3.jpg',
        mainSliderDescr: 'Первый модуль для проработки страхов, обид и вины. Начинайте работать над собой именно с него.',
        mainColor: '#E3BE12',
        isPayed: false,
        mainHeaderBg: '/images/groups-bg/inner-group-3.png',
        meditationTheme: 'dark',
        playerImage: '/images/groups-bg/player.jpg',
        list: [
          {
            url: 'izbavlenie-ot-straha-obidy-viny-i-dr',
            promoImage: '/images/meditations-bg-sq/8.png',
            id: 8,
            isNew: false,
            groupId: 'ya-nichego-ne-boyus',
            isActive: true,
            type: 'mediatation',
            name: 'Избавление от страха, обиды, вины и др.',
            nameHTML: 'Шаг 1',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Это не просто медитация, а полноценная проработка. После медитации вы уже не будете прежней, это первый шаг к себе настоящей, сильной, уверенной. Наши страхи, обиды, чувства вины, зависти и др. мешают жить полной жизнью и делать то, что мы действительно хотим. Главная задача этой медитации - избавиться от ненужных дискомфортных чувств. После медитации у вас освободится колоссальное количество энергии, которую вы используете для достижения своих целей.</p><p>Медитация подходит всем, она заменит вам поход к психотерапевту, сэкономит время и деньги. Перед тем, как приступить к прослушиванию, вспомните ту самую неприятную ситуацию, где вы чувствовали себя очень некомфортно, испытывали страх, чувство вины, обиду, раздражение или что-то ещё. Эту медитацию можно повторять много раз для разных запросов.</p>',
            discrTime: '53 мин',
            discrReviews: [],
            discrPrice: 25,
            audio: 'https://elena.shop/public/audio/samostoyatelnaya_prorabotka.mp3',
            audioPreview: 'https://elena.shop/public/audio/samostoyatelnaya_prorabotka-preview.mp3',
            getCourseIframeID: 1047837,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/2-3-1.jpg',
              '/images/review/2-3-2.jpg',
              '/images/review/2-3-3.jpg',
              '/images/review/2-3-4.jpg',
              '/images/review/2-3-5.jpg',
              '/images/review/2-3-6.jpg',
              '/images/review/2-3-7.jpg',
              '/images/review/2-3-8.jpg',
              '/images/review/2-3-9.jpg',
              '/images/review/2-3-10.jpg',
              // '/images/review/2-3-11.jpg',
              // '/images/review/2-3-12.jpg',
              // '/images/review/2-3-13.jpg',
              // '/images/review/2-3-14.jpg',
              // '/images/review/2-3-15.jpg',
              // '/images/review/2-3-16.jpg',
              // '/images/review/2-3-17.jpg',

            ],
          },
          {
            url: 'otpuskanie_travmi',
            id: 12,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/12.png',
            groupId: 'ya-nichego-ne-boyus',
            isActive: false,
            type: 'mediatation',
            name: 'Отпускание травмы',
            nameHTML: 'Шаг 2',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Эта медитация поможет вам прожить по-новому волнующее вас событие жизни, то событие, которое произошло с вами в прошлом.</p>'
            + '<p>Вы сможете отпустить все негативные эмоции и чувства, которые застряли в вашем теле. У вас получится увидеть опыт, который несла данная ситуация, увидеть причину, по которой вы возвращаетесь к этой травме снова и снова.</p>'
            + '<p>Осознание опыта даёт облегчение и освобождает место для новых счастливых событий в вашей жизни.</p>'
            + '<p>В конце медитации вас ждёт волшебная практика для исполнения мечты.</p>',
            discrTime: '28 мин',
            discrReviews: [],
            discrPrice: 25,
            audio: 'https://elena.shop/public/audio/otpuskanie_travmi.mp3',
            audioPreview: 'https://elena.shop/public/audio/otpuskanie_travmi-preview.mp3',
            getCourseIframeID: 1047838,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/2-5-1.jpg',
              '/images/review/2-5-2.jpg',
              '/images/review/2-5-3.jpg',
              '/images/review/2-5-4.jpg',
            ],
          },
          {
            url: 'osvobozhdenie-ot-rodovyh-strahov-i-trevog',
            id: 3,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/3.png',
            groupId: 'ya-nichego-ne-boyus',
            isActive: false,
            type: 'mediatation',
            name: 'Освобождение от родовых страхов и тревог',
            nameHTML: 'Шаг 4',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Обычно люди проживают аналогичную жизнью, как у своих родителей, перенимают страхи, тревоги, повторяют родовые сценарии. Вы должны взять на себя ответственность и наконец остановить повторение родовых ошибок. Моя исцеляющая медитация поможет вам в этом. Главная задача этой медитации - отпустить родовые страхи, мягко и экологично трансформировать их в любовь и вашу внутреннюю силу. После медитации вы почувствуете невероятное облегчение и прилив энергии, начнете проживать свою жизнь и делать то, что действительно хотите.</p>',
            discrTime: '16 мин',
            discrReviews: [],
            discrPrice: 20,
            audio: 'https://elena.shop/public/audio/ochischenie-ot-rodovih-strahov.mp3',
            audioPreview: 'https://elena.shop/public/audio/ochischenie-ot-rodovih-strahov-preview.mp3',
            getCourseIframeID: 1047842,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/2-4-1.jpg',
              '/images/review/2-4-2.jpg',
              '/images/review/2-4-3.jpg',
              '/images/review/2-4-4.jpg',
              '/images/review/2-4-5.jpg',
              '/images/review/2-4-6.jpg',
              '/images/review/2-4-7.jpg',
              '/images/review/2-4-8.jpg',
              '/images/review/2-4-9.jpg',
            ],
          },
          {
            url: 'vosstanovlenie-vashey-zhiznennoy-sily',
            id: 2,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/2.png',
            groupId: 'ya-nichego-ne-boyus',
            isActive: false,
            type: 'mediatation',
            name: 'Восстановление вашей жизненной силы',
            nameHTML: 'Бонус',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>У каждой женщины внутри есть большой нераскрытый потенциал, мы с этим живем и часто можем даже не догадываться о его существовании. В этой медитации мы вместе с вами отправимся в путешествие к вашему месту силы и будем творить чудеса. Главная задача медитации -  восстановить вашу жизненную энергию, раскрыть потенциал. После медитации вы почувствуете себя целостной, очень наполненной и гармоничной.</p>',
            discrTime: '20 мин',
            discrReviews: [],
            discrPrice: 20,
            audio: 'https://elena.shop/public/audio/vosstanovlenie_vashey_zhiznennoy_sily.mp3',
            audioPreview: 'https://elena.shop/public/audio/vosstanovlenie_vashey_zhiznennoy_sily-preview.mp3',
            getCourseIframeID: 1047844,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/2-2-1.jpg',
              '/images/review/2-2-2.jpg',
              '/images/review/2-2-3.jpg',
              '/images/review/2-2-4.jpg',
              '/images/review/2-2-5.jpg',
              '/images/review/2-2-6.jpg',
              '/images/review/2-2-7.jpg',
              '/images/review/2-2-8.jpg',
            ],
          },
        ],
      },
      {
        groupName: 'vebinars',
        saleForAll: 0,
        title: 'Вебинары',
        singleTitle: 'Вебинары',
        mainColor: '#FD84AB',
        isPayed: false,
        mainSliderImage: 'images/groups-bg/group-1.jpg',
        meditationTheme: 'light',
        mainSliderDescr: '',
        reviews: [
        ],
        mainHeaderBg: '/images/groups-bg/inner-group-1.png',
        playerImage: '/images/groups-bg/player-2.jpg',
        list: [
          {
            url: 'zakony-rodovoy-sistemy',
            id: 'wb_21',
            isNew: false,
            promoImage: '/images/meditations-bg-sq/wb_21.png',
            groupId: 'ya-skoro-budu-mamoy',
            isActive: false,
            type: 'webinar',
            name: 'Законы родовой системы',
            nameHTML: 'Шаг 9',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>Этот вебинар важно пройти всем женщинам, но особенно тем, кто планирует беременность. Мы сделаем диагностику вашей родовой системы, увидим нарушения и правильно проведем коррекцию. Вы почувствуете огромную силу и поддержку своего рода, ощутите свою ценность и принадлежность к нему. Эти осознания помогут вам по другому взглянуть на вашу родовую систему и еще лучше подготовят вас к продолжению рода. На вебинаре будет много практических заданий: практика сепарации от родителей, практика на активацию и восстановление силы вашего рода, практика на отпускание нерожденных деток и многое другое.</p>',
            discrTime: '4 ч. 17 мин',
            discrReviews: [],
            discrPrice: 30,
            audio: 'MBxEnQ-j5yM',
            audioPreview: 'Sw190BBQ81I',
            getCourseIframeID: 1111,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/1-2-1.jpg',
              '/images/review/1-2-2.jpg',
              '/images/review/1-2-3.jpg',
              '/images/review/1-2-4.jpg',
              '/images/review/1-2-5.jpg',
              '/images/review/1-2-6.jpg',
              '/images/review/1-2-7.jpg',
            ],
          },
          {
            url: 'kak-pravilno-zagadyvat-zhelaniya-chtoby-oni-sbyvalis',
            id: 6,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/6.png',
            groupId: 'ya-prinimayu-sebya',
            isActive: false,
            type: 'webinar',
            name: 'Как правильно загадывать желания, чтобы они сбывались',
            nameHTML: 'Бонус',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>На вебинаре я дам четкий пошаговый алгоритм загадывания желаний, который сама проверила на практике. У вас больше не возникнет вопроса: «Почему мои желания не сбываются из года в год?». Бонус для тех, кто планирует беременность - на вебинаре мы в общем поле сделаем медитацию “Встреча с будущим малышом”. Главная задача вебинара - научиться получать от жизни всё, жить в гармонии и радости. После вебинара, вы еще лучше узнаете себя, научитесь управлять своей жизнью.</p>',
            discrTime: '1 ч. 7 мин.',
            discrReviews: [],
            discrPrice: 30,
            audio: 'UdyM0TrIyBA',
            audioPreview: 'xO7vSFEYTtY',
            getCourseIframeID: 1111,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/3-3-1.jpg',
              '/images/review/3-3-2.jpg',
              '/images/review/3-3-3.jpg',
            ],
          },
          {
            url: 'biologicheskie-zakony-prirody',
            id: 9,
            isNew: false,
            promoImage: '/images/meditations-bg-sq/9.png',
            groupId: 'ya-nichego-ne-boyus',
            isActive: false,
            type: 'webinar',
            name: 'Биологические законы природы. Как правильно понимать своё тело',
            nameHTML: 'Шаг 3',
            isPayed: false,
            isPayPending: false,
            discrText: '<p>На вебинаре я расскажу, как перестать бояться болезней и поверить в сверхсилу своего организма. Мы с вами рассмотрим принципы функционирования вашего тела, разберём 5 биологических законов природы, которые объясняют процесс естественного восстановления тела. Главная задача этого вебинара - расширить вашу картину Мира, перестать бояться болезней, ощутить сверхсилу своего организма. После вебинара вы найдёте ответы на многие ваши вопросы, углубитесь в изучение психосоматики.</p>',
            discrTime: '1 ч. 11 мин.',
            discrReviews: [],
            discrPrice: 30,
            audio: 'DD61GjWZrr8',
            audioPreview: 'vWtxjWnUwAA',
            getCourseIframeID: 1111,
            getCoursePrice: 1000,
            reviews: [
              '/images/review/2-1-1.jpg',
            ],
          },
        ],
      },
    ],
    newMeditationsList: [
    ],
    currentMeditation: {},
    currentMeditationGroup: {},
    legacyCartData: [],
    payedDataForRequest: [],
    lastPayedCount: 0,
    meditationTextPostfix: '<p>Хочу подчеркнуть, что медитация - это не волшебная таблетка, а инструмент, который поможет расслабиться и услышать своё тело. На протяжении всей медитации я буду вашим проводником, буду задавать правильные вопросы и направлять вас. Однако, вы должны помнить, что 100% результата лежит только на вас. Во время практики вас ничего не должно отвлекать, вы должны убрать все посторонние мысли, максимально погрузиться в медитацию, визуализировать всё, что в ней происходит.</p> <p> После медитации вы сможете поделиться своими ощущениями в нашем чате Телеграм. У вас появится доступ к чату, где сейчас общаются более 10 тысяч женщин. После прослушивания моих медитаций и просмотра вебинаров они делятся своими осознаниями и успехами, вдохновляют и мотивируют друг друга, обсуждают вопросы психосоматики и беременности.</p> <p> Не откладывайте на потом и получите полный доступ к курсу сейчас. Для этого нажмите на кнопки ниже и выберите тариф.</p>',
    webinarTextPostfix: '<p>Хочу подчеркнуть, что вебинар в записи и вы сможете пересматривать его несколько раз. Во время вебинара вас ничего не должно отвлекать, вы должны убрать все посторонние мысли, максимально погрузиться в общее поле и работать вместе со мной.</p> <p>После вебинара вы сможете поделиться своими ощущениями в нашем чате Телеграм. У вас появится доступ к чату, где сейчас общаются более 10 тысяч женщин. После прослушивания моих медитаций и просмотра вебинаров они делятся своими осознаниями и успехами, вдохновляют и мотивируют друг друга, обсуждают вопросы психосоматики и беременности.</p> <p>Не откладывайте на потом и получите полный доступ к курсу сейчас. Для этого нажмите на кнопки ниже и выберите тариф.</p> ',
    meditationTextPostfixPayed: '<p>Хочу подчеркнуть, что медитация - это не волшебная таблетка, а инструмент, который поможет расслабиться и услышать своё тело. На протяжении всей медитации я буду вашим проводником, буду задавать правильные вопросы и направлять вас. Однако, вы должны помнить, что 100% результата лежит только на вас. Во время практики вас ничего не должно отвлекать, вы должны убрать все посторонние мысли, максимально погрузиться в медитацию, визуализировать всё, что в ней происходит. </p> <p> У вас появился доступ к чату, где сейчас общаются более 10 тысяч женщин. Они делятся своими осознаниями и успехами, вдохновляют и мотивируют друг друга, обсуждают вопросы психосоматики и беременности. После медитации обязательно поделитесь своими ощущениями в нашем чате Телеграм.</p>',
    webinarTextPostfixPayed: '<p>Во время просмотра вебинара вас ничего не должно отвлекать, вы должны убрать все посторонние мысли, максимально погрузиться в общее поле и работать вместе со мной.</p> <p> У вас появился доступ к чату, где сейчас общаются более 10 тысяч женщин. Они делятся своими осознаниями и успехами, вдохновляют и мотивируют друг друга, обсуждают вопросы психосоматики и беременности. После вебинара обязательно поделитесь впечатлением в нашем чате Телеграм.</p>',
    FBPurchaseEventID: '',
    tempPriceToPay: '',
    cartList: [],
    webinarRegType: '',
  },
  mutations: {
    setWebinarRegType(state, data) {
      state.webinarRegType = data;
    },
    addToCart(state, data) {
      state.cartList.push(data);
    },
    removeFromCart(state, data) {
      state.cartList.forEach((item, index) => {
        if (item === data) {
          state.cartList.splice(index, 1);
        }
      });
    },
    clearCart(state) {
      state.cartList = [];
    },
    setTempPriceToPay(state, data) {
      state.tempPriceToPay = data;
    },
    setCurrentPage(state, data) {
      state.meditationsList.forEach((group) => {
        if (group.groupName === data.group) {
          /* Установим текущую группу */
          state.currentMeditationGroup = group;
        }

        group.list.forEach((meditation) => {
          if (meditation.url === data.name) {
            state.currentMeditation = meditation;
          }
        });
      });
    },
    /* Пометим все не опрлаченные медитации модуля для оплаты. Покупка модуля целиком */
    markCourseMeditationsForPay(state) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          currentItem.isPayPending = false;

          if (!meditation.isPayed) {
            currentItem.isPayPending = true;
          }
        });
      });
    },
    markAllMeditationNotForPay(state) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];
          currentItem.isPayPending = false;
        });
      });
    },
    /* Сделаем так, что бы остались помечеными к оплате только медитации из нужной группы */
    markSingleMeditationsForPay(state, payload) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (payload !== group.groupName && !meditation.isPayed) {
            currentItem.isPayPending = false;
          }
        });
      });
    },
    /* Пометим все не опрлаченные медитации для оплаты. Покупка курса целиком */
    markModuleMeditationsForPay(state, payload) {
      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          currentItem.isPayPending = false;

          if (payload === group.groupName && !meditation.isPayed) {
            currentItem.isPayPending = true;
          } else {
            currentItem.isPayPending = false;
          }
        });
      });
    },
    markMeditationForNotPayByID(state, payload) {
      const currentId = payload;

      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (String(meditation.id) === String(currentId)) {
            currentItem.isPayPending = false;
          }
        });
      });
    },
    markMeditationForPayByID(state, payload) {
      const currentId = payload.id;

      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          if (String(meditation.id) === String(currentId)) {
            currentItem.isPayPending = true;
          }
        });
      });
    },
    /* Пометим медитации которые идут в оплату */
    markMeditationForPay(state, payload) {
      const currentId = state.currentMeditation.id;
      const currentGroupName = state.currentMeditationGroup.groupName;

      state.meditationsList.forEach((group, groupIndex) => {
        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          currentItem.isPayPending = false;

          if (payload.isFullPackageSelected) {
            if (group.groupName === currentGroupName && !meditation.isPayed) {
              currentItem.isPayPending = true;
            }
          } else if (String(meditation.id) === String(currentId)) {
            currentItem.isPayPending = true;
          }
        });
      });
    },
    /* Помечаем группу купленной целиком */
    markGroupAsPayed(state, data) {
      state.meditationsList.forEach((group, index) => {
        const groupItem = state.meditationsList[index];

        if (group.groupName === data) {
          groupItem.isPayed = true;
        }
      });
    },
    /* Длеаем помеченные медитации оплачеными */
    markAsPayed(state, data) {
      const saveData = {
        payed: [],
        paidForTransaction: [],
        inCart: state.legacyCartData,
        payedPackages: [],
      };
      let dataArray = [];
      let lastPayedCount = 0;

      if (data) {
        dataArray = data.ids.split(',');
      }

      state.meditationsList.forEach((group, groupIndex) => {
        if (group.isPayed) {
          saveData.payedPackages.push(group.groupName);
        }

        group.list.forEach((meditation, index) => {
          const currentItem = state.meditationsList[groupIndex].list[index];

          // если dataArray пустой (оплата не чеерез Яндекс) и медитация в payPending
          if (meditation.isPayPending && !dataArray.length) {
            currentItem.isPayed = true;
            currentItem.isPayPending = false;
            lastPayedCount += 1;
            saveData.paidForTransaction.push(meditation.id);
          }

          // если dataArray есть то оплата была через Яндекс
          // и нужно пробежаться по списку оплаенных
          dataArray.forEach((payedFromYaId) => {
            if (String(payedFromYaId) === String(meditation.id)) {
              currentItem.isPayed = true;
              currentItem.isPayPending = false;
              lastPayedCount += 1;
              saveData.paidForTransaction.push(meditation.id);
            }
          });

          if (meditation.isPayed) {
            saveData.payed.push(meditation.id);
          }
        });
      });
      state.payedDataForRequest = saveData.paidForTransaction;
      state.lastPayedCount = lastPayedCount;
      localStorage.setItem('meditationAudio', JSON.stringify(saveData));
    },
    setDataFromLocalStorage(state) {
      if (localStorage.getItem('meditationAudio')) {
        const avialibleList = state.meditationsList;
        const data = JSON.parse(localStorage.getItem('meditationAudio'));
        const isFirstPayed = localStorage.getItem('isAudioPayed');

        avialibleList.forEach((group, groupIndex) => {
          const currentGroup = state.meditationsList[groupIndex];

          if (data.payedPackages) {
            data.payedPackages.forEach((payedPackage) => {
              if (payedPackage === group.groupName) {
                currentGroup.isPayed = true;
              }
            });
          }

          group.list.forEach((meditation, index) => {
            const currentItem = state.meditationsList[groupIndex].list[index];

            // легаси штука, проверяет если есть старая кука то пометит как купленную
            if (meditation.id === 1 && isFirstPayed) {
              currentItem.isPayed = true;
            }

            data.payed.forEach((payedItem) => {
              if (payedItem === meditation.id) {
                currentItem.isPayed = true;
              }
            });

            // обратная совместимость со старой версией, что бы у людей не потерялись данные корзины
            if (data.inCart) {
              state.legacyCartData = data.inCart;
            }
          });
        });
      }
    },
    setFBPurchaseEventID(state, data) {
      state.FBPurchaseEventID = data;
    },
  },
  actions: {
    savePayedData({ commit, state, getters }, payload) {
      // шлем название курса только если оплачивают весь курс
      let packageName = getters.getIsFullPackageSelected ? state.currentMeditationGroup.groupName : '';
      const { coursePaymentType } = getters;

      if (payload) {
        packageName = payload.packageName;
      }

      if (packageName !== '') {
        commit('markGroupAsPayed', packageName);
      }

      if (payload) {
        commit('markAsPayed', payload);
      } else {
        commit('markAsPayed');
      }

      if (getters.getUserMail !== '') {
        axios
          .post(`${getters.getLocalUrl}/v2/api/saveUserData.php`, {
            mail: getters.getUserMail,
            payed: state.payedDataForRequest,
            packageName,
            coursePaymentType,
            sendMail: true,
          })
          .then((response) => {
            if (response.data.success) {
              console.log('Данные пользователя успешно сохранены');
            }
          });
      }
      if (typeof window.fbq !== 'undefined') {
        const contentType = state.payedDataForRequest.length > 1 ? 'product_group' : 'product';
        const stringIsdArray = state.payedDataForRequest.map((item) => item.toString());
        let price = getters.getPriceToPay;

        console.log('event sended');

        if (Number.isNaN(price)) {
          price = state.tempPriceToPay;
        }

        window.fbq('track', 'Purchase', {
          content_ids: stringIsdArray,
          content_type: contentType,
          currency: 'USD',
          value: price,
        }, {
          eventID: state.FBPurchaseEventID,
        });
      }

      commit('setFBPurchaseEventID', '');
      commit('clearCart');
    },
    /* Метож нигде уже не используется, но оставил, возможно позже понадобится */
    showPromoModal({ commit, state, getters }) {
      /* eslint-disable */
      const name = 'isPromoVisible';
      const cookieMatches = document.cookie.match(new RegExp(
        `(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`,
      ));
      const hasActiveCookie = cookieMatches ? decodeURIComponent(cookieMatches[1]) : undefined;
      /* eslint-enable */

      if (hasActiveCookie) {
        return false;
      }

      if (!getters.getUserPromo) {
        return false;
      }

      // если дата есть, значит таймер уже активирован и нам не нужно уведомляха
      if (getters.getUserPromo.validDate) {
        return false;
      }

      // Если оплачен то не открываем модалку
      if (state.currentMeditation.isPayed) {
        return false;
      }

      setTimeout(() => {
        commit('showDialogByType', 'promo-congrats');
        document.cookie = 'isPromoVisible=true; max-age=1200; path=/;';
      }, 2000);

      return true;
    },
  },
  modules: {
  },
  getters: {
    isCurrentPackagePayed: (state) => state.currentMeditationGroup.isPayed,
    getPandingMeditations: (state) => {
      const resultData = {
        count: 0,
        list: [],
      };

      state.meditationsList.forEach((groupItem) => {
        groupItem.list.forEach((meditationItem) => {
          if (meditationItem.isPayPending) {
            resultData.list.push(meditationItem.id);
            resultData.count += 1;
          }
        });
      });

      return resultData;
    },
    getPayedMeditations: (state) => {
      const resultData = {
        count: 0,
        list: [],
      };

      state.meditationsList.forEach((groupItem, groupIndex) => {
        resultData.list.push({
          list: [],
          title: groupItem.title,
          groupName: groupItem.groupName,
          reminigPriceWithSale: 0,
        });

        let fullPrice = 0;

        groupItem.list.forEach((meditationItem) => {
          if (meditationItem.isPayed) {
            resultData.list[groupIndex].list.push(meditationItem);
            resultData.count += 1;
          } else {
            fullPrice += meditationItem.discrPrice;
          }
        });

        fullPrice *= (groupItem.saleForAll / 100);
        resultData.list[groupIndex].reminigPriceWithSale = fullPrice;
      });

      return resultData;
    },
    getAllMeditations: (state) => {
      const resultData = {
        count: 0,
        list: [],
      };

      state.meditationsList.forEach((groupItem) => {
        groupItem.list.forEach((meditationItem) => {
          if (meditationItem.type === 'mediatation') {
            resultData.list.push(meditationItem);
            resultData.count += 1;
          }
        });
      });

      return resultData;
    },
    getAllWebinars: (state) => {
      const resultData = {
        count: 0,
        list: [],
      };

      state.meditationsList.forEach((groupItem) => {
        groupItem.list.forEach((meditationItem) => {
          if (meditationItem.type === 'webinar') {
            resultData.list.push(meditationItem);
            resultData.count += 1;
          }
        });
      });

      return resultData;
    },
    getNotPayedMeditations: (state) => {
      const resultData = {
        count: 0,
        list: [],
      };

      state.meditationsList.forEach((groupItem) => {
        groupItem.list.forEach((meditationItem) => {
          if (!meditationItem.isPayed && meditationItem.type === 'mediatation') {
            resultData.list.push(meditationItem);
            resultData.count += 1;
          }
        });
      });

      return resultData;
    },
    infoForMeditationsInCart(state) {
      const info = {
        price: 0,
        priceWithSale: 0,
        saleCoof: 0,
        list: [],
      };
      const { cartList } = state;

      state.meditationsList.forEach((group) => {
        group.list.forEach((item) => {
          cartList.forEach((cartListItemID) => {
            if (cartListItemID === item.id) {
              info.price += item.discrPrice;
              info.list.push(item);
            }
          });
        });
      });

      info.saleCoof = 0.5;

      info.priceWithSale += info.price - (info.price * info.saleCoof);

      return info;
    },
    meditationsAndWebinarsList: (state) => {
      const info = [
        {
          title: 'Медитации',
          list: [],
        },
        {
          title: 'Вебинары',
          list: [],
        },
      ];

      state.meditationsList.forEach((group) => {
        group.list.forEach((item) => {
          if (item.type === 'webinar') {
            info[1].list.push(item);
          }

          if (item.type === 'mediatation') {
            info[0].list.push(item);
          }
        });
      });

      return info;
    },
    getMeditationsInfoForSingle: (state) => {
      const info = {
        groupsWithNoPayedItems: [],
        noPayedMeditations: [],
        payedMeditations: [],
        readyForPayIds: [],
        totalPrice: 0,
        pendingWebinars: [],
        pendingMeditations: [],
        priceForNoPayed: 0,
        hasPayed: false,
      };

      state.meditationsList.forEach((group) => {
        const groupData = {
          groupTitle: group.title,
          groupSingleTitle: group.singleTitle,
          groupName: group.groupName,
          groupImage: group.mainSliderImage,
          groupDescr: group.mainSliderDescr,
          groupLink: `/meditation-group/${group.groupName}/${group.list[0].url}`,
          noPayedMeditations: [],
          payedMeditations: [],
          allMeditationsList: [],
          isFullModule: true,
          priceForPending: 0,
          noPayedPrice: 0,
        };

        group.list.forEach((item) => {
          info.totalPrice += item.discrPrice;

          if (!item.isPayed) {
            info.priceForNoPayed += item.discrPrice;
            groupData.noPayedPrice += item.discrPrice;

            info.noPayedMeditations.push(item);
            groupData.noPayedMeditations.push(item);

            if (item.isPayPending) {
              info.readyForPayIds.push(item.id);
              groupData.priceForPending += item.discrPrice;

              if (item.type === 'webinar') {
                info.pendingWebinars.push(item.name);
              }

              if (item.type === 'mediatation') {
                info.pendingMeditations.push(item.name);
              }
            }
          } else {
            groupData.isFullModule = false;
            groupData.payedMeditations.push(item);
            info.payedMeditations.push(item);
          }

          groupData.allMeditationsList.push(item);
        });

        info.groupsWithNoPayedItems.push(groupData);
      });

      info.hasPayed = info.totalPrice !== info.priceForNoPayed;

      return info;
    },
    getMeditationsInfoForCourse: (state) => {
      const info = {
        groupsWithNoPayedItems: [],
        meditation: [],
        webinars: [],
        readyForPayIds: [],
        totalPrice: 0,
        priceForNoPayed: 0,
        hasPayed: false,
      };

      state.meditationsList.forEach((group) => {
        const groupData = {
          groupTitle: group.title,
          groupName: group.groupName,
          groupImage: group.mainSliderImage,
          groupDescr: group.mainSliderDescr,
          groupLink: `/meditation-group/${group.groupName}/${group.list[0].url}`,
          meditations: [],
          webinars: [],
          isFullModule: true,
          noPayedPrice: 0,
        };

        group.list.forEach((item) => {
          info.totalPrice += item.discrPrice;

          if (!item.isPayed) {
            info.priceForNoPayed += item.discrPrice;
            groupData.noPayedPrice += item.discrPrice;

            if (item.type === 'webinar') {
              info.webinars.push(item.name);
              groupData.webinars.push(item.name);
            }

            if (item.type === 'mediatation') {
              info.meditation.push(item.name);
              groupData.meditations.push(item.name);
            }

            if (item.isPayPending) {
              info.readyForPayIds.push(item.id);
            }
          } else {
            groupData.isFullModule = false;
          }
        });

        info.groupsWithNoPayedItems.push(groupData);
      });

      info.hasPayed = info.totalPrice !== info.priceForNoPayed;

      return info;
    },
    getReadyToPayIdsData: (state) => {
      const readyForPayIds = [];

      state.meditationsList.forEach((group) => {
        group.list.forEach((item) => {
          if (item.isPayPending) {
            readyForPayIds.push({
              id: item.id,
              name: item.name,
              price: item.discrPrice,
            });
          }
        });
      });

      return readyForPayIds;
    },
    getReadyToPayIds: (state) => {
      const readyForPayIds = [];

      state.meditationsList.forEach((group) => {
        group.list.forEach((item) => {
          if (item.isPayPending) {
            readyForPayIds.push(item.id);
          }
        });
      });

      return readyForPayIds;
    },
    getMeditationsInfo: (state) => {
      const info = {
        meditation: [],
        webinars: [],
        readyForPayIds: [],
        totalPrice: 0,
        priceForNoPayed: 0,
        hasPayed: false,
      };

      if (!state.currentMeditationGroup.list) {
        return false;
      }

      state.currentMeditationGroup.list.forEach((item) => {
        info.totalPrice += item.discrPrice;
        if (!item.isPayed) {
          info.priceForNoPayed += item.discrPrice;
          if (item.type === 'webinar') {
            info.webinars.push(item.name);
          }

          if (item.type === 'mediatation') {
            info.meditation.push(item.name);
          }

          if (item.isPayPending) {
            info.readyForPayIds.push(item.id);
          }
        }
      });

      info.hasPayed = info.totalPrice !== info.priceForNoPayed;

      return info;
    },
    getGeneralMeditationInfo: (state) => {
      const info = {
        hasPayed: false,
      };

      state.meditationsList.forEach((group) => {
        group.list.forEach((meditation) => {
          if (meditation.isPayed) {
            info.hasPayed = true;
          }
        });
      });

      return info;
    },
    isLastMeditationOnGroup: (state) => {
      let payedCount = 0;

      state.currentMeditationGroup.list.forEach((item) => {
        if (item.isPayed) {
          payedCount += 1;
        }
      });

      return state.currentMeditationGroup.list.length - payedCount <= 1;
    },
  },
});
