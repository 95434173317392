<template>
    <div class="webinar-invite">
        <div class="webinar-invite__title">Регистрация на <br>вебинар</div>
        <div class="webinar-invite__time">
            <div class="webinar-invite__lrg-text">{{time}}</div>
            <div class="webinar-invite__small-text">Московское время</div>
        </div>
        <div class="es-dialog__text">
            Выберите мессенджер, куда прислать вашу ссылку на вебинар. Нажмите на иконку.

            <div class="webinar-instr__subscribe-variants">
                <a @click="moveToMessenger(tgLink)" class="webinar-instr__subscribe-item">
                    <div class="webinar-instr__subscribe-image">
                        <img src="/images/icon-telegram.svg"/>
                    </div>
                </a>
                <a @click="moveToMessenger(viberLink)" class="webinar-instr__subscribe-item">
                    <div class="webinar-instr__subscribe-image">
                        <img src="/images/icon-viber.svg"/>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'WebinarInvite',
  data() {
    return {
      viber1900: 'viber://pa?chatURI=elena-psy&context=ml2',
      viber1500: 'viber://pa?chatURI=elena-psy&context=ml3',
      telegram1900: 'tg://resolve?domain=elena_psybot&start=ml2',
      telegram1500: 'tg://resolve?domain=elena_psybot&start=ml3',
    };
  },
  computed: {
    webinarRegType() {
      return this.$store.state.meditations.webinarRegType;
    },
    viberLink() {
      return this.webinarRegType === '1500' ? this.viber1500 : this.viber1900;
    },
    tgLink() {
      return this.webinarRegType === '1500' ? this.telegram1500 : this.telegram1900;
    },
    time() {
      return this.webinarRegType === '1500' ? '15:00' : '19:00';
    },
  },
  mounted() {

  },
  methods: {
    closeDialog() {
      this.$store.commit('hideDialog');
    },
    moveToMessenger(link) {
      if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'Lead');
      }

      window.location.href = link;
    },
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.webinar-invite {
  margin: 20px 0 0;

    &__title {
        color: #105CFF;
        font-size: 17px;
        text-align: center;
        line-height: 24px;
        margin-bottom: 25px;
    }

    &__lrg-text {
        color: #105CFF;
        font-size: 45px;
        text-align: center;
        font-weight: 600;
    }

    &__small-text {
        color: #105CFF;
        font-size: 11px;
        text-align: center;
    }

    .es-dialog__text {
        margin-bottom: 30px;
        font-size: 14px;

        strong {
            font-weight: 600;
        }
    }
}

 .webinar-instr{
     &__subscribe-variants {
         display: flex;
         justify-content: center;
         align-items: flex-start;
         margin: 25px 0;
     }

     &__subscribe-item {
         font-size: 9px;
         line-height: 14px;
         width: 60px;
         text-align: center;
         margin: 0 8px;
         cursor: pointer;
         display: block;
         text-decoration: none;
         color: $black;
     }

     &__subscribe-image {
         margin-bottom: 8px;

         img {
             width: 43px;
         }
     }
 }
</style>
