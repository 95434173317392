<template>
    <div class="get-course-form">
        <div class="es-dialog__text">
            <div class="get-course-form__title-small">Тариф</div>
            <div class="get-course-form__title">Cеребряный ключик</div>
            <iframe src="https://course.elena.shop/pl/lite/widget/widget?&amp;id=988401&amp;ref=&amp;loc=https%3A%2F%2Felena.shop%2FtestPayemnt.html" allowfullscreen="allowfullscreen" class="789" id="f9c5419134b3da7b13f8d360f2a5203e39c10a91_789" name="789" style="width: 100%; height: 500px; border: none; overflow: hidden;"></iframe>
        </div>
    </div>
</template>

<script>

export default {
  name: 'GetCourseFormSilver',
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
 @import "../../../assets/scss/common/variables";
 @import "../../../assets/scss/common/dialog";

.get-course-form {
  margin: 40px -20px 0 -20px;

    &__title-small {
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;
        margin-bottom: 5px;
    }

    &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
    }
}
</style>
